<template>
  <b-card no-body class="p-1">
    <b-overlay :show="state.loading">
      <div class="demo-spacing-0 m-1"
           dir="ltr"
      >
        <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="primary" @click="isActive=true">
          فیلتر
          <feather-icon icon="FilterIcon"/>
        </b-button>
      </div>

      <div>
        <item-field-base key-name="status"
                         tooltip="اصلاح مقدار"
                         :address="'/coins/'"
        />
      </div>


      <search-and-filter
        :is-active.sync="isActive"
        :options="columns"
        @filter="getData(1, perPage)"
        @reset="getData(1, perPage)"/>

      <div class="relative-over-x">
        <b-table
          ref="refUserListTable"
          :items="items"
          :small="true"
          responsive
          :fields="columns"
          primary-key="id"
          show-empty
          empty-text="اطلاعاتی یافت نشد"
          @sort-changed="sort($event)"
          no-local-sort
          style="white-space: nowrap; min-height : 235px"
        >

          <template #cell(coinLabel)="data">
            <b-media vertical-align="center">
              <b-avatar
                size="25"
                class="mr-50"
                :src="data.item.coin.logo"
                variant="light-info"
                v-tooltip="data.item.coin.code"
              />
              {{ data.item.coin.label }}
            </b-media>
          </template>

          <template #cell(email)="data">
                        <span>
                            {{ data.item.user.email }}
                        </span>
          </template>

          <template #cell(type)="data">
            <b-badge
              pill
              :variant="'light-'+typeVariant(data.item.type)"
            >
              {{ typeLabel(data.item.type) }}
            </b-badge>
          </template>


          <template #cell(Address)="data">
                        <span>
                            {{ data.item.payment.address }}
                        </span>
          </template>

          <template #cell(amount)="data">
                        <span dir="ltr">
                            {{ $toLocal(data.item.amount) }}
                        </span>
          </template>


          <template #cell(txId)="data">
            <!--                        <b-link :href="data.item.viewInExplorer || '#'" target="_blank">-->
            {{ data.item.payment.tx_id }}
            <!--                        </b-link>-->
          </template>

          <template #cell(created_at)="data">
                        <span dir="ltr">
                            {{ $G2J(data.item.created_at) }}
                        </span>
          </template>

          <template #cell(status)="{item,index}">
            <b-badge
              pill
              :variant="'light-'+statusVariant(item.status || 'CANCELED')"
              class="text-capitalize"
            >
              {{ statusLabel(item.status) }}
            </b-badge>
            <feather-icon
              v-if="(item.status === 'SENT' && item.type === 'WITHDRAW') || (item.status === 'PENDING' && item.type === 'DEPOSIT')"
              icon="RefreshCcwIcon"
              size="20"
              class="cursor-pointer text-warning mx-1"
              :class="{'rotate' : check[index]}"
              v-tooltip="'بررسی تراکنش'"
              @click="getStatus(item,index)"
            />
          </template>

        </b-table>
      </div>

      <!-- pagination -->
      <div
        class="demo-spacing-0 d-flex justify-content-between m-1"
        dir="rtl"
      >
        <b-pagination
          v-model="currentPage"
          :total-rows="rows"
          :per-page="perPage"
          first-number
          last-number
          prev-class="prev-item"
          next-class="next-item"
          align="left"
          @input="getData(currentPage,perPage)"
        >
          <template #prev-text>
            <feather-icon
              icon="ChevronRightIcon"
              size="18"
            />
          </template>
          <template #next-text>
            <feather-icon
              icon="ChevronRightIcon"
              size="18"
            />
          </template>
        </b-pagination>
        <div>
          <label for="perpage">تعداد در صفحه</label>
          <v-select
            id="perpage"
            v-model="perPage"
            dir="rtl"
            :options="perPageOptions"
            :clearable="false"
            class="per-page-selector d-inline-block mx-50"
            style="min-width: 85px"
            @input="getData(1,perPage)"
          />
        </div>
      </div>
    </b-overlay>
  </b-card>
</template>

<script>
import {
  BPagination,
  BCard,
  BButton,
  BTable,
  // BDropdown,
  // BDropdownItem,
  BBadge,
  BAvatar,
  // BLink,
  BOverlay,
  BMedia,
} from 'bootstrap-vue'
import SearchAndFilter from "@/layouts/components/SearchAndFilter";
import vSelect from 'vue-select';
import ItemFieldBase from "@/Components/ItemFieldBase";

export default {
  name: 'Withdraw',
  components: {
    SearchAndFilter,
    BPagination,
    BCard,
    BButton,
    BTable,
    // BDropdown,
    // BDropdownItem,
    BBadge,
    BAvatar,
    vSelect,
    // BLink,
    BOverlay,
    BMedia,
    ItemFieldBase,
  },
  data: () => ({
    test: 0,
    pageLength: 3,
    dir: false,
    searchTerm: '',
    currentPage: 1,
    perPage: 10,
    rows: 10,
    sortBy: '',
    isSortDirDesc: '',
    userData: [],
    check: [],
    isActive: false,
    perPageOptions: [5, 10, 20, 50, 100],
    items: [],
    columns: [
      {
        label: 'رمز ارز',
        key: 'coinLabel',
        sortable: false,
        searchType: 'text',
      },
      {
        label: 'نام کاربر',
        key: 'email',
        sortable: false,
        searchType: 'text'
      },
      {
        label: 'نوع انتقال',
        key: 'type',
        sortable: false,
        searchType: 'select',
        selectOptions: [
          {label: 'برداشت', value: 'WITHDRAW'},
          {label: 'واریز', value: 'DEPOSIT'},
        ]
      },
      {
        label: 'مقدار انتقال',
        key: 'amount',
        sortable: false,
        searchType: 'number'
      },
      {
        label: 'کیف پول',
        key: 'address',
        sortable: false,
      },
      {
        label: 'هش لینک',
        key: 'txId',
        sortable: false,
      },
      {
        label: 'تاریخ درخواست',
        key: 'created_at',
        sortable: false,
        searchType: 'date'
      },
      {
        label: 'وضعیت',
        key: 'status',
        sortable: false,
        searchType: 'select',
        selectOptions: [
          {label: 'در حال انجام', value: 'PENDING'},
          {label: 'لغو شده', value: 'REJECTED'},
          // {label: 'لغو شده توسط کاربر', value: 'CANCELLED_BY_USER'},
          {label: 'انجام شده', value: 'ACCEPTED'},
        ]
      },
    ],
  }),
  created() {
    this.getData()
  },
  computed: {
    statusVariant() {
      const a = {
        ACCEPTED: 'success',
        SENT: 'info',
        PENDING: 'info',
        DEACTIVATED: 'warning',
        CONFLICT: 'danger',
        REJECTED: 'danger',
        FAILED: 'danger'
      }
      return e => a[e]
    },
    statusLabel() {
      const a = {
        ACCEPTED: 'موفق',
        SENT: 'ارسال شده',
        PENDING: 'در حال انجام',
        DEACTIVATED: 'غیر فعال',
        CONFLICT: 'عدم تطابق',
        REJECTED: 'رد شده',
        FAILED: 'عدم موفقیت'
      }
      return e => a[e]
    },
    typeVariant() {
      const a = {
        WITHDRAW: 'danger',
        DEPOSIT: 'success',
      }
      return e => a[e];
    },
    typeLabel() {
      const a = {
        WITHDRAW: 'برداشت',
        DEPOSIT: 'واریز',
      }
      return e => a[e];
    }
  },
  methods: {
    async getData(page, perPage) {
      this.state.loading = true
      const queryParams = {
        size: perPage,
        page: page,
        coinType: 'crypto',
        ...this.$route.query
      }
      const res = await this.$axios.get(
        '/transactions',
        {
          params: queryParams
        })
      this.state.loading = false
      this.items = res.data.data
      this.currentPage = res.data.meta.current_page
      this.rows = res.data.meta.total
    },
    sort(e) {
      console.log(e)

      let sort = this.$toSnakeCase(e.sortBy)
      // let sort = e.sortBy
      let sorting = e.sortDesc ? 'DESC' : 'ASC'

      this.$router.push({
        query: {
          ...this.$route.query,
          orderBy: sort,
          sorting: sorting
        }
      })

      this.getData(1, this.perPage)

    },
    async getStatus(item, i) {
      try {
        if (!this.check[i]) {
          this.check[i] = true
          this.$forceUpdate()
          if (item.type === 'WITHDRAW') {
            await this.$axios.patch('/transactions/' + item.id + '/check-withdraw')
          } else {
            await this.$axios.patch('/transactions/' + item.id + '/check-deposit')
          }
          this.check[i] = false
          this.$forceUpdate()
          this.getData(this.page, this.perPage)
        }
      } catch (e) {
        this.check[i] = false
        this.$forceUpdate()
      }
    }
  }
}
</script>
<style lang="scss">
[v-cloak] {
  opacity: 0;
}

.nowrap {
  white-space: nowrap;
}

[dir="rtl"] .test-badge {
  font-size: 10px;
  top: -6px;
  left: -5px !important;
  min-width: 16px;
  min-height: 16px;
}
</style>
